import dayjs from "dayjs";
import { startOfDay } from "date-fns";

export const getIfTodayIsAValidDayToUploadTaxes = () => {
    const today = dayjs().toDate()
    const monthDay26 = startOfDay(new Date(dayjs().set('date', 26).toISOString()))
    const todayIsAValidDayToUploadTaxes = today <= monthDay26

    console.log(todayIsAValidDayToUploadTaxes)

    return todayIsAValidDayToUploadTaxes
}
